import React from 'react'
import Container from 'components/common/Container'
import { Details, TermsOfServiceWrapper, Wrapper } from './styles'

export default () => {
  return (
    <Wrapper id="algemene-voorwaarden">
      <TermsOfServiceWrapper as={Container}>
        <Details>
          <h1>ALGEMENE VOORWAARDEN</h1>
          <p>
            Deze algemene voorwaarden zijn van toepassing op al de service contracten tussen CloudBuilder en de klant,
            en vervangen de algemene voorwaarden van de klant, tenzij CloudBuilder deze expliciet aanvaard heeft via een
            formeel schrijven.
          </p>
          <p>
            Door het ondertekenen van een offerte, het plaatsen van een order of het aanvaarden van een gelijkaardig
            document van CloudBuilder, zoals via email, via de CloudBuilder website of door het ondertekenen van een
            contract, erkent de klant onze Algemene voorwaarden te begrijpen en te aanvaarden zoals die te lezen staan
            op www.cloudbuilder.be/voorwaarden.
          </p>
          <p>
            Offertes door CloudBuilder gemaakt zijn geldig gedurende 30 kalender dagen, tenzij dit anders vermeld wordt
            op dezelfde offerte. Alle prijzen zijn exclusief BTW, tenzij anders vermeld.
          </p>
          <p>
            Offertes die door CloudBuilder worden gemaakt zijn niet automatisch van toepassing voor latere bestellingen,
            en zijn in geen geval bindend om een deel van de offerte uit te laten voeren voor de voorgestelde deelprijs.
          </p>
          <p>
            Prijzen zijn altijd onder voorbehoud van eventuele drukfouten en/of rekenfouten en kunnen door CloudBuilder
            aangepast worden zonder dat de klant het recht heeft om de foutieve prijs te eisen.
          </p>
          <p>
            We kunnen te allen tijde onze prijzen herzien, maar we geven de klant 30 dagen de tijd – om in geval van een
            prijsstijging – op het einde van deze periode het contract op te zeggen.
          </p>
          <p>
            Tenzij anders vermeld zijn al de CloudBuilder facturen te betalen binnen de 10 kalender dagen volgend op de
            factuur datum.
          </p>
          <p>
            In geval van een laattijdige betaling zal de klant CloudBuilder – zonder extra verwittiging – een
            conventionele compensatie betalen van 10% van het totale factuurbedrag met een minimum van 150€, alsook een
            conventionele interest van 8% die aan het begin van elke daarop volgende maand wordt toegevoegd. Indien er
            een aangetekend schrijven wordt gestuurd voor of door CloudBuilder is de klant wettelijk verplicht deze
            extra kosten te betalen voor een geschatte vaste kost van 40€. Indien de klant niet of maar gedeeltelijk
            betaald, of te laat betaald, heeft CloudBuilder het recht om de geleverde diensten stop te zetten. Deze
            beslissing om de diensten te stoppen geeft de klant op geen enkele manier het recht tot enige vorm van
            compensatie.
          </p>
          <p>
            Alle betalingen worden gedaan in Euro op de rekeningen zoals die op de facturen van CloudBuilder terug te
            vinden zijn. Eventuele kosten die worden aangerekend voor een bepaalde betaling zullen altijd ten laste van
            de klant vallen.
          </p>
          <p>Wat onze diensten betreft:</p>
          <ul>
            <li>
              De klant mag onze diensten en/of applicaties niet gebruiken voor enige vorm van illegale activiteiten of
              anderen de mogelijkheid geven dit te doen.
            </li>
            <li>
              De klant moet zich houden aan de toepasselijke wetgeving en de algemene voorschriften.
            </li>
            <li>
              De klant zal geen processen uitvoeren of programma’s opstarten die een negatief effect kunnen hebben op de
              infrastructuur van CloudBuilder en/of zijn klanten.
            </li>
            <li>
              De klant zal onze diensten niet gebruiken om ongeoorloofd toegang te verkrijgen tot eender welk systeem.
            </li>
            <li>
              Enkel de klant is verantwoordelijk voor het gebruik van onze diensten, inclusief de confidentialiteit van
              bepaalde data, het gebruik van toegangscodes, email adressen, shares,…
            </li>
            <li>
              Mocht CloudBuilder door een derde partij verantwoordelijk worden gesteld omdat een klant zijn diensten
              heeft gebruikt, zal deze klant CloudBuilder vergoeden voor elke financiële eis.
            </li>
            <li>
              De klant mag op geen enkele manier rechten of verplichtingen die vallen onder zijn overeenkomst met
              CloudBuilder overdragen aan een derde partij zonder een geschreven akkoord van CloudBuilder.
            </li>
            <li>
              CloudBuilder bekijkt op geen enkel moment de inhoud van bestanden en/of communicatie tenzij ze daartoe
              verplicht zouden worden door de wetgevende macht of op vraag van de klant om bijvoorbeeld problemen op te
              lossen.
            </li>
            <li>
              CloudBuilder zal al het mogelijke doen om de diensten op te leveren zoals die in onze SLA’s worden
              beschreven.
            </li>
            <li>
              De maandelijks toegelaten data transfer en het gebruik van webservers mag de afgesproken quota niet
              overschrijden. Wanneer dit toch gebeurd heeft CLoudBuilder het recht om de nodige acties te ondernemen en
              extra werk aan te rekenen.
            </li>
          </ul>
          <p>
            Al de oplever termijnen die door CloudBuilder worden aangegeven zijn enkel indicatief van aard en kunnen
            door de klant niet als bindend worden gebruikt tenzij dit door CloudBuilder expliciet op papier word
            vermeld.
          </p>
          <p>
            Wanneer we niet op tijd kunnen opleveren omdat de klant niet aan al de voorwaarden voldoet (geen stroom,
            geen info, geen configuratie,…) hebben we het recht om ons extra werk en de extra tijd aan te rekenen.
          </p>
          <p>
            CloudBuilder kan op geen enkele manier verantwoordelijk worden gesteld voor direct of indirect verlies van
            een klant zoals – zonder zich tot deze lijst te beperken – commercieel of financieel verlies, verlies van
            data, verlies van reputatie, verlies van winst of omzet, verlies van klanten of verlies door de acties van
            derden tegen onze klanten.
          </p>
          <p>
            Elektronische communicatie tussen CloudBuilder en de klant heeft dezelfde waarde als een normale brief en
            wordt door beide partijen als bewijs aanvaard.
          </p>
          <p>
            Alle overeenkomsten tussen CloudBuilder en de klant worden uitsluitend beheerst door het Belgisch recht.
            Alle geschillen tussen CloudBuilder en de klant vallen onder de exclusieve bevoegdheid van de rechtbanken
            van het gerechtelijk arrondissement Leuven, België.
          </p>
        </Details>
      </TermsOfServiceWrapper>
    </Wrapper>
  )
};
